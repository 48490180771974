<template>
	<div>
		<!-- banner header -->
		<CRow class="gradient-background-02">
			<CCol sm="12" class="content-center">
				<img :src="images.imgLady02" class="img-fluid" alt="" />
			</CCol>
		</CRow>
		<br />
		<!-- informacion -->
		<CRow>
			<CCol sm="12">
				<p
					class="justify-text"
					style="color:black; font-family: 'Montserrat-Bold'; font-size:18px;"
				>
					HAZ ELIMINADO TU WIDGET EN NUESTRO PORTAL
				</p>
				<p
					class="justify-text"
					style="color:black; font-family: 'Montserrat-Regular';font-size:14px;"
				>
					Si quieres revertir esta decisi&oacute;n comun&iacute;cate
					con tu Ejecutivo de Ventas.
				</p>
				<p
					class="justify-text"
					style="color:black; font-family: 'Montserrat-Regular';font-size:14px;"
				>
					Te invitamos a no desperdiciar este importante complemento
					para aumentar tu visibilidad, posicionamiento y alcance.
				</p>
			</CCol>
		</CRow>
		<!-- botones -->
		<CRow>
			<CCol sm="12" class="content-center">
				<b-button
					@click="logOut"
					pill
					variant="info"
					size="lg"
					class="mr-1"
				>
					Cerrar Sesi&oacute;n
				</b-button>
			</CCol>
		</CRow>
	</div>
</template>

<script>
	export default {
		name: 'dataDeleted',
		title: 'Info Feed | Infoguia',
		data() {
			return {
				images: {
					imgLady02: process.env.VUE_APP_LADY_02,
				},
			};
		},
		methods: {
			logOut() {
				this.$store
					.dispatch('auth/logout')
					.then((response) => {
						this.$router.push({
							name: 'Login',
						});
					})
					.catch((error) => {
						console.log(error.response);
						this.$router.push({
							name: 'Login',
						});
					});
			},
		},
	};
</script>

<style></style>
