<template>
	<b-modal
		v-model="triggerModal"
		centered
		no-close-on-backdrop
		header-bg-variant="danger"
		header-text-variant="light"
		style="text-align:justify;"
		size="lg"
	>
		<CRow>
			<CCol>
				<p class="h6 justify-text">
					Estas intentando eliminar la data de tu widget de
					<b>Instagram</b> en <b>Infoguia</b>:
				</p>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<p class="h6 justify-text">
					<span class="h6 badge badge-danger">Importante: </span>
					Recuerda que si eliminas tu data perder&aacute;s tu widget
					de <b>Instagram</b> en <b>Infoguia</b> y todos sus
					beneficios.
				</p>
			</CCol>
		</CRow>
		<CRow>
			<CCol sm="12">
				<p class="h6 justify-text">
					Si deseas realmente eliminar tus datos con nosotros por
					favor escribe
					<b>Eliminar</b> en el siguiente campo y luego haz clic en el
					bot&oacute;n <b>Eliminar</b> una vez se habilite el mismo.
				</p>
			</CCol>
		</CRow>
		<CRow>
			<CCol sm="4">
				<CInput v-model="textConfirm"></CInput>
			</CCol>
		</CRow>
		<template v-slot:modal-header>
			<h6 class="modal-title">Eliminar mis datos</h6>
			<CButtonClose @click="closeModal" class="text-white" />
		</template>
		<template v-slot:modal-footer>
			<b-button
				@click="deleteData"
				pill
				variant="danger"
				size="lg"
				class="mr-1"
				:disabled="textConfirm !== 'Eliminar' ? true : false"
			>
				Eliminar
			</b-button>
			<b-button
				@click="closeModal"
				pill
				variant="info"
				size="lg"
				class="mr-1"
				>No eliminar
			</b-button>
		</template>
	</b-modal>
</template>

<script>
	export default {
		name: 'deleteCustomerData',
		props: {
			triggerModal: {
				type: Boolean,
				default: function() {
					return false;
				},
			},
		},
		data() {
			return {
				textConfirm: '',
			};
		},
		methods: {
			closeModal() {
				this.textConfirm = '';
				this.$emit('closeModal');
			},
			deleteData() {
				this.$emit('deleteData');
			},
		},
	};
</script>

<style></style>
