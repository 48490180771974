<template>
	<div>
		<!-- banner header -->
		<CRow class="gradient-background-01">
			<CCol sm="12" class="content-center">
				<img :src="images.imgLady03" class="img-fluid" alt="" />
			</CCol>
		</CRow>
		<br />
		<!-- informacion -->
		<CRow>
			<CCol sm="12">
				<p
					class="justify-text"
					style="color:black; font-family: 'Montserrat-Bold'; font-size:18px;"
				>
					¡SABIA DECISI&Oacute;N!
				</p>
				<p
					class="justify-text"
					style="color:black; font-family: 'Montserrat-Regular';font-size:14px;"
				>
					Nos alegra saber que seguir&aacute;s disfrutando del
					InfoFeed.
				</p>
				<p
					class="justify-text"
					style="color:black; font-family: 'Montserrat-Regular';font-size:14px;"
				>
					Estamos seguros de que te traer&aacute; muchos beneficios y
					clientes potenciales
				</p>
			</CCol>
		</CRow>
		<!-- botones -->
		<CRow>
			<CCol sm="12" class="content-center">
				<b-button
					@click="logOut"
					pill
					variant="info"
					size="lg"
					class="mr-1"
				>
					Cerrar Sesi&oacute;n
				</b-button>
			</CCol>
		</CRow>
	</div>
</template>

<script>
	export default {
		name: 'dataNoDeleted',
		title: 'Info Feed | Infoguia',
		data() {
			return {
				images: {
					imgLady03: process.env.VUE_APP_LADY_03,
				},
			};
		},
		methods: {
			logOut() {
				this.$store
					.dispatch('auth/logout')
					.then((response) => {
						this.$router.push({
							name: 'Login',
						});
					})
					.catch((error) => {
						console.log(error.response);
						this.$router.push({
							name: 'Login',
						});
					});
			},
		},
	};
</script>

<style></style>
