<template>
	<div>
		<CCard>
			<loading-screen v-if="isLoading" :information-text="textLoading" />
			<!-- <CCardHeader>
				<h4 class="center-text">
					¡Hola {{ userInfo.name }}, Bienvenido a InfoFeed!
				</h4></CCardHeader
			> -->
			<CCardBody>
				<!-- modal error -->
				<error-modal v-if="showErrorModal" :trigger-modal="true" />
				<delete-customer-data
					v-if="showDeleteModal"
					:trigger-modal="true"
					@closeModal="showDeleteModal = false"
					@deleteData="deleteData"
				/>
				<div v-if="showThis === 'information'">
					<CAlert v-if="userInfo.idCustomer === null" color="danger">
						<h5 class="center-text">
							No posees un widget de <b>Instagram</b> activo
							actualmente con nosotros.
						</h5>
					</CAlert>
					<!-- banner header -->
					<CRow class="gradient-background-01">
						<CCol sm="12" class="content-center">
							<img
								:src="images.imgLady01"
								class="img-fluid"
								alt=""
							/>
						</CCol>
					</CRow>
					<br />
					<!-- informacion -->
					<CRow>
						<CCol sm="12">
							<p
								class="justify-text"
								style="color:black; font-family: 'Montserrat-Bold'; font-size:18px;"
							>
								¡EST&Aacute;S A UN PASO DE ELIMINAR TU WIDGET DE
								INSTAGRAM EN INFOGUIA!
							</p>
							<p
								class="justify-text"
								style="color:black; font-family: 'Montserrat-Regular';font-size:14px;"
							>
								Si eliminas los datos de la cuenta de Instagram
								registrada, afectar&aacute;s considerablemente
								la visibilidad y posicionamiento de tu perfil en
								nuestro portal.
							</p>
						</CCol>
					</CRow>
					<br />
					<!-- lista 1 -->
					<CRow>
						<CCol sm="12">
							<p
								class="justify-text"
								style="color:black; font-family: 'Montserrat-Bold'; font-size:18px;"
							>
								ANTES DE CONTINUAR CONSIDERA QUE...
							</p>
							<p
								class="justify-text"
								style="color:black; font-family: 'Montserrat-Regular';font-size:14px;"
							>
								Eliminar tu widget de Instagram también afectará
								los siguientes aspectos:
							</p>
							<ul
								style="color:black; font-family: 'Montserrat-Regular';font-size:14px;"
							>
								<li>
									<img :src="images.bulletPoints" alt="" />
									<b>Confianza y credibilidad:</b> los
									usuarios no podr&aacute;n conocerte a
									trav&eacute;s de las Redes Sociales.
								</li>
								<li>
									<img :src="images.bulletPoints" alt="" />
									<b>Posicionamiento:</b> Instagram es una red
									importante para construir identidad de marca
									para cualquier empresa.
								</li>
								<li>
									<img :src="images.bulletPoints" alt="" /><b
										>Clientes potenciales:</b
									>
									llegar&aacute;s a una mayor cantidad de
									leads.
								</li>
							</ul>
						</CCol>
					</CRow>
					<br />
					<!-- lista 2 -->
					<CRow>
						<CCol sm="12">
							<p
								class="justify-text"
								style="color:black; font-family: 'Montserrat-Bold'; font-size:18px;"
							>
								PASOS PARA ELIMINAR TU WIDGET DE INSTAGRAM
							</p>
							<ol
								class="justify-text"
								style="color:black; font-family: 'Montserrat-Regular';font-size:14px;"
							>
								<li>
									<span style="color:#fff100;"
										><i
											class="fas fa-check-square fa-lg"
										></i
									></span>
									Ingresa en tu cuenta de <b>Instagram</b> y
									ve a <b>Configuraci&oacute;n</b>.
								</li>
								<li>
									<span style="color:#fff100;"
										><i
											class="fas fa-check-square fa-lg"
										></i
									></span>
									Busca la opci&oacute;n
									<b>Aplicaciones/Sitios Web</b> y seleccionas
									la pestaña <b>Activas</b>.
								</li>
								<li>
									<span style="color:#fff100;"
										><i
											class="fas fa-check-square fa-lg"
										></i
									></span>
									Eliminas el acceso y regresas de nuevo a
									esta pantalla para que borres los datos
									desde el sistema <b>InfoFeed</b>.
								</li>
							</ol>
						</CCol>
					</CRow>
					<br />
					<!-- botones -->
					<CRow>
						<CCol sm="12" class="content-center">
							<b-button
								@click="showDeleteModal = true"
								pill
								variant="outline-danger"
								size="lg"
								class="mr-1"
								:disabled="
									userInfo.idCustomer !== null ? false : true
								"
							>
								Eliminar
							</b-button>
							<b-button
								v-if="userInfo.idCustomer !== null"
								@click="dontDelete"
								pill
								variant="info"
								size="lg"
								class="mr-1"
								>No eliminar
							</b-button>
							<b-button
								v-else
								@click="logOut"
								pill
								variant="info"
								size="lg"
								class="mr-1"
								>Cerrar Sesi&oacute;n
							</b-button>
						</CCol>
					</CRow>
				</div>
				<data-deleted v-else-if="showThis === 'dataDeleted'" />
				<data-no-deleted v-else-if="showThis === 'dataNoDeleted'" />
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
	import LoadingScreen from '../../components/loadingScreen.vue';
	import DeleteCustomerData from '../../components/modals/deleteCustomerData.vue';
	import ErrorModal from '../../components/modals/errorModal.vue';
	import functionsForCompany from '../../functions/functionsForCompany';
	import dataDeleted from '../../components/deleteData/dataDeleted.vue';
	import DataNoDeleted from '../../components/deleteData/dataNoDeleted.vue';
	export default {
		components: {
			DeleteCustomerData,
			LoadingScreen,
			ErrorModal,
			dataDeleted,
			DataNoDeleted,
		},
		name: 'deleteData',
		title: 'Info Feed | Infoguia',
		data() {
			return {
				userInfo: {},
				images: {
					imgLady01: process.env.VUE_APP_LADY_01,
					bulletPoints: process.env.VUE_APP_BULLET_POINTS_LIST,
					number1: process.env.VUE_APP_NUMBER_1,
					number2: process.env.VUE_APP_NUMBER_2,
					number3: process.env.VUE_APP_NUMBER_3,
				},
				loggedOut: false,
				titleModal: '',
				textModal: '',
				showDeleteModal: false,
				showErrorModal: false,
				isLoading: false,
				textLoading: '',
				showErrorModal: false,
				showThis: 'information',
			};
		},
		created() {
			this.userInfo = JSON.parse(sessionStorage.getItem('userData'));
		},
		methods: {
			dontDelete() {
				this.showThis = 'dataNoDeleted';
			},
			deleteData() {
				this.isLoading = true;
				this.showDeleteModal = false;
				this.textLoading =
					'Estamos eliminando tu data, por favor espera ...';
				functionsForCompany
					.deleteCustomerData(this.userInfo.idCustomer)
					.then((response) => {
						this.showThis = 'dataDeleted';
					})
					.catch((error) => {
						this.showErrorModal = true;
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			logOut() {
				this.isLoading = true;
				this.textLoading =
					'Cerrando la sesi&oacute;n...';
				this.$store
					.dispatch('auth/logout')
					.then((response) => {
						this.$router.push({
							name: 'Login',
						});
					})
					.catch((error) => {
						console.log(error.response);
						this.$router.push({
							name: 'Login',
						});
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
		},
	};
</script>

<style>
	.fa-check-square {
		filter: drop-shadow(-2px -2px 3px gray);
	}
</style>
